import styled, { css } from 'styled-components';
import { left } from '@popperjs/core';
import { FlexCenterOneItem, FlexRowAlignCenter } from '@components/wrapper';
import { CustomText } from '@components/ui';

export const Badge = styled.div`
  position: absolute;
  right: -6px;
  top : 1.5px;
  width: 4px;
  height: 4px;
  background-color: #4e4eff;
  border-radius: 50%;
`;

export const TextWrapperForBadge = styled.div`
  position: relative;
`;

export const GNBTabContainer = styled(FlexRowAlignCenter)<{ marginLeft: number }>`
  position: relative;

  ${(props) => `
    margin-left: ${props.marginLeft}px;
  `}
  a {
    text-decoration: none;
    transition: 0.1s;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const SubGNBTabContainer = styled(FlexRowAlignCenter)<{ isBadge?: boolean; left?: number }>`
  position: relative;
  ${props => props.left && css`
    & + .subMenus::before {
      left: -${props.left}px;
    }
  `}
  
  a {
    text-decoration: none;
    transition: 0.1s;
  }
`;

export const CountBadge = styled(FlexCenterOneItem)`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffe9fa;
  margin-left: 8px;
`;

export const EnhancedCustomText = styled(CustomText)`
  font-size: 16px!important;
  font-weight: 600!important;
  line-height: 24px!important;

  @media screen and (max-width: 768px) {
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 100%!important;
  }
`;
