import React from 'react';
import { IconProps } from '../type';
import * as Styled from '../styled';

type Props = IconProps & {
  customStroke?: number;
};

export default function CloseIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.1693 9.99769L14.748 6.42538C15.0743 6.09886 15.0743 5.56946 14.748 5.24294C14.4216 4.91642 13.8925 4.91642 13.5662 5.24294L9.99585 8.82358L6.42552 5.24294C6.09918 4.91642 5.57008 4.91642 5.24374 5.24294C4.91739 5.56946 4.91739 6.09886 5.24374 6.42538L8.82239 9.99769L5.24374 13.57C5.08618 13.7264 4.99756 13.9392 4.99756 14.1612C4.99756 14.3833 5.08618 14.5961 5.24374 14.7524C5.4 14.9101 5.61272 14.9988 5.83463 14.9988C6.05654 14.9988 6.26926 14.9101 6.42552 14.7524L9.99585 11.1718L13.5662 14.7524C13.7225 14.9101 13.9352 14.9988 14.1571 14.9988C14.379 14.9988 14.5917 14.9101 14.748 14.7524C14.9055 14.5961 14.9942 14.3833 14.9942 14.1612C14.9942 13.9392 14.9055 13.7264 14.748 13.57L11.1693 9.99769Z"
          fill="#88888A"
        />
      </svg>
    </Styled.IconWrapper>
  );
}
