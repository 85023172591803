import React from 'react';
import styled, { css } from 'styled-components';
import { getColor } from '@util/themeUtil';
import { IHeaderContainerProps, IHeaderTopBannerContainer } from './Header.types';

export const HeaderContainerSizeAdjust = styled.div<IHeaderContainerProps>`
  display: none;
  ${(props) => {
    const interpolation = props.viewportWidth / 1024;
    const zoom = (props.isNewHeader || interpolation > 1) ? 1 : interpolation;
    return css`
      & + div.header {
        top: -${props.top}px;
        height: ${props.bannerHeight}px;
      }
      & + div + div {
        --zoom: ${zoom};
      }
   `;
  }};
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: sticky;
  z-index: 102;
  background-color: ${(props) => props.theme.colors.gnbBg};
  & + div {
    zoom: var(--zoom);
    -webkit-text-size-adjust: calc(100% * var(--zoom));
    -moz-text-size-adjust: calc(100% * var(--zoom));
    -ms-text-size-adjust: calc(100% * var(--zoom));
    text-size-adjust: calc(100% * var(--zoom));
  }
  &:has(.hasSubmenu:hover), &:has(.isSubmenu.isActive) {
    box-shadow: 0 48px 0 ${getColor('gray', 50)},
                0 1px 0 48px ${getColor('gray', 100)};
  }
  &:has(.isSubmenu.isActive) {
    & + div {
      padding-top: calc(48px / var(--zoom));
    }
  }
`;

export const HeaderContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gnbBg};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const HeaderGnbContainer = styled.div<React.CSSProperties>`
  max-width: 1444px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeaderTopBannerContainer = styled.div<IHeaderTopBannerContainer>`
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
