import React, { Dispatch, Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import classnames from 'classnames';
import gnbMenuList from 'public/gnbMenuList.json';
import { HoverItem } from '@components/wrapper';
import Grid from '@components/layout/Grid/Grid';
import CategoryIcon from 'src/assets/icons/CategoryIcon';
import { LANGUAGE_CODE } from 'src/constants';
import useSettings from '@hooks/useSettings';
import { classNameToName, SubGNBTab } from '../tab';
import { Category } from './category';
import { Wrapper, Container, CategoryButton, DevideLine, DevideLineContainer, CommonLi, SubMenus, SubMenu } from './styled';

interface props {
  isMobile: boolean;
  isShowExplore: boolean;
  setIsShowExplore: Dispatch<any>;
  setSubmenuOpened?: Dispatch<any>;
}
type TGnbItem = {
  key: string;
  href?: null | string;
  tagging?: null | string;
  isActivePath?: null | string | string[];
  subMenu?: {
    key: string;
    href: null | string;
    tagging: null | string;
    isActivePath: null | string | string[];
  }[];
};

export const SubGnb: React.FC<props> = ({ isMobile, isShowExplore, setIsShowExplore }) => {
  const { themeMode } = useSettings();
  const router = useRouter();
  const { t, i18n } = useTranslation(['game', 'menu']);

  const handleExploreButtonClick = () => {
    !isMobile && setIsShowExplore(true);
  };
  const handleCategoryClose = () => setIsShowExplore(false);
  const handleCategoryRemoveClick = () => {
    location.hash = '#';
    handleCategoryClose();
  };

  const CategoryWrapper = useCallback(
    ({ children }) => {
      return (
        <>
          <HoverItem className={'h-full'} onClick={()=> {
            isMobile && router.push('/category');
          }}>
            <CategoryButton onClick={handleExploreButtonClick} data-mixpanel-action="click" data-mixpanel-evt="GNB Click, GNB Click_category" data-mixpanel-name="category">
              {children}
            </CategoryButton>
          </HoverItem>
          {!isMobile && <Category isShow={isShowExplore} isMobile={false} onClick={handleCategoryRemoveClick} onClose={handleCategoryClose} />}
        </>
      );
    },
    [isShowExplore, isMobile],
  );

  useEffect(() => {
    // URL에 #category가 있을 경우 모델 표시 3D 모델찾기 모달 표시
    if (!isShowExplore && location.hash === '#category') {
      setIsShowExplore(true);
    }
  }, [typeof location !== 'undefined' && location.hash]);

  const theme = useTheme();

  const gnbMenuListProcessed = gnbMenuList[themeMode] as TGnbItem[] & { get: (key: string) => TGnbItem };
  gnbMenuListProcessed.get = function (key: string) {
    return this.find((item) => item.key === key);
  };

  return (
    <Wrapper themeMode={themeMode}>
      <Grid>
        <Grid.Item>
          <Container themeMode={themeMode}>
            {gnbMenuListProcessed
              .filter((item) => item.key !== 'adult' || (item.key === 'adult' && i18n.language !== LANGUAGE_CODE.CN))
              .filter((item) => item.key !== 'community' || (item.key === 'community' && i18n.language === LANGUAGE_CODE.KO))
              .map((item) => {
                const isCategory = item.key === 'category';
                const isDevideLine = item.key === 'devideLine';
                const isVisualNovel = item.key === 'game.visualNovel';
                if (isCategory) {
                  return (
                    <CategoryWrapper key={item.key}>
                      <SubGNBTab
                        href={item.href}
                        isMobile={isMobile}
                        iconComponent={<CategoryIcon size={24} />}
                        iconMargin={8}
                        className={item.tagging}
                        displayName={t('menu:subgnb.findModel')}
                      />
                    </CategoryWrapper>
                  );
                }
                if (isDevideLine) {
                  return (
                    <DevideLineContainer key={item.key}>
                      <DevideLine
                        style={{
                          backgroundColor: theme.colors.activeText,
                        }}
                      />
                    </DevideLineContainer>
                  );
                }
                return (
                  <HoverItem key={item.key} className={'h-full relative'
                    + (gnbMenuListProcessed.get(item.key) && gnbMenuListProcessed.get(item.key).subMenu ? ' hasSubmenu' : '')}>
                    <CommonLi
                      className={classnames({
                        isActive:
                          item.isActivePath && (typeof item.isActivePath === 'string' ? router.pathname === item.isActivePath : item.isActivePath.includes(router.pathname)),
                      })}
                    >
                      <SubGNBTab isMobile={isMobile} className={item.tagging} displayName={t(`menu:subgnb.${item.key}`)} href={item.href} isBadge={isVisualNovel}/>
                      {gnbMenuListProcessed.get(item.key) && gnbMenuListProcessed.get(item.key).subMenu && (
                        <SubMenus>
                          <div>
                            {item.subMenu.map((subItem) => (
                              <SubMenu
                                key={subItem.key}
                                href={subItem.href}
                                className={classnames({
                                  isSubmenu: true,
                                  isActive:
                                    subItem.isActivePath &&
                                    (typeof subItem.isActivePath === 'string' ? router.pathname === subItem.isActivePath : subItem.isActivePath.includes(router.pathname)),
                                })}
                                data-mixpanel-action={'click'}
                                data-mixpanel-evt={`GNB Click, GNB Click_${classNameToName[subItem.tagging]}`}
                                data-mixpanel-name={classNameToName[subItem.tagging]}
                              >
                                {t(`menu:subgnb.${subItem.key}`)}
                              </SubMenu>
                            ))}
                          </div>
                        </SubMenus>
                      )}
                    </CommonLi>
                  </HoverItem>
                );
              })}
          </Container>
        </Grid.Item>
      </Grid>
    </Wrapper>
  );
};
