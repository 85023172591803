import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { datadogRum } from '@datadog/browser-rum';
import { sha256 } from 'js-sha256';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import {
  HeaderContainer,
  HeaderContentContainer,
  HeaderGnbContainer,
  HeaderTopBannerContainer,
} from '@components/layout/Header/Header.styles';
import { useUserInfo } from '@hooks';
import { GET_HEADER_INFO } from '@api';
import { CommonContext, UserInfoStatusEnum } from 'src/provider';
import { convertLangCodeForDB } from '@lib';
import useResponsiveDevice from '@hooks/useResponsiveDevice';
import { BAND_BANNER_COOKIE_KEY, GA_ACON_USER_ID_COOKIE_KEY } from './Header.constants';
import Gnb from './components/Gnb/Gnb';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import { SubGnb } from './components/subGnb';
import { BandBanner } from './components/BandBanner';

const HeaderContainerSizeAdjust = dynamic(() => import('@components/layout/Header/Header.styles').then((styles) => styles.HeaderContainerSizeAdjust), { ssr: false });

const Header = ({ isNewHeader }: { isNewHeader?: boolean }): React.ReactElement => {
  const { i18n } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { width } = useResponsiveDevice(0);
  const [isMobile, setIsMobile] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width, router.pathname]);

  const language = convertLangCodeForDB(i18n.language);

  const { userInfo, status } = useUserInfo();
  const { carts, cartsOnSession, cashData } = useContext(CommonContext);

  const cartsCount = useMemo(() => (status === UserInfoStatusEnum.authenticated ? carts : cartsOnSession)?.length, [status, carts, cartsOnSession]);

  const [isShowExplore, setIsShowExplore] = useState(false);
  const [hasMounted, setHasmounted] = useState(false);

  // 쿠키에 저장된 배너 숨김 정보
  const bannerCookie = cookies[BAND_BANNER_COOKIE_KEY] || {};

  // 밴드 배너 데이터 조회
  const { data: headerInfoItem } = useQuery(GET_HEADER_INFO, { variables: { lang: language } });
  // 배너 데이터
  const bandBannerData = headerInfoItem?.getBandBannerForPage.filter((e) => {
    const hideUntil = bannerCookie[e.id]?.hideUntil;
    return !hideUntil || hideUntil < Date.now();
  });

  const bannerHeight = isMobile ? 0 : (hasMounted ? bandBannerData?.length || 0 : 0) * 44;
  const headerHeight = (isMobile ? 88 : 140) + bannerHeight;
  const headerTop = (isMobile ? 50 : 92) + bannerHeight;

  // 파라미터에 배너 id를 입력하면 해당 id의 쿠키를 저장하는 callback을 리턴합니다.
  const genRemoveCallbackByBannerId = (id) => {
    // 밴드 배너 항목을 삭제하는 이벤트 처리기 메소드입니다.
    return (e) => {
      e.preventDefault();
      // 쿠키 만료일자
      const expiry = new Date();
      // 쿠키 만료일자를 오늘일자 YYYY-MM-DDT23:59:59 로 세팅
      expiry.setHours(23, 59, 59, 999);

      bannerCookie[id] = { hideUntil: expiry };
      setCookie(BAND_BANNER_COOKIE_KEY, bannerCookie, { path: '/' });
    };
  };

  const bandBanners = bandBannerData?.map((data, i) => {
    return <BandBanner key={i} isMobile={isMobile} onClickRemove={genRemoveCallbackByBannerId(data.id)} {...data} />;
  });

  // kakao
  const isKakaoScriptLoad = typeof window !== 'undefined' && Boolean(window.Kakao);

  useEffect(() => {
    const jsKey = '5b58fea3856a0c411926e0340a61626d';

    // SDK는 한 번만 초기화해야 한다.
    // 중복되는 초기화를 막기 위해 isInitialized()로 SDK 초기화 여부를 판단한다.
    if (isKakaoScriptLoad && !window.Kakao?.isInitialized()) {
      // JavaScript key를 인자로 주고 SDK 초기화
      window.Kakao.init(jsKey);
    }
  }, [isKakaoScriptLoad]);

  useEffect(() => {
    if (userInfo) {
      datadogRum.setUser({
        id: userInfo.userId,
      });
      // GTM 에서 사용하는 유저 식별 정보 쿠키 설정
      setCookie(GA_ACON_USER_ID_COOKIE_KEY, sha256(userInfo.userId), { path: '/' });
    } else {
      // GTM 에서 사용하는 유저 식별 정보 쿠키 삭제 (유저 정보가 없으면 로그인 상태가 아닌 걸로 간주)
      removeCookie(GA_ACON_USER_ID_COOKIE_KEY, { path: '/' });
    }
  }, [userInfo?.userId]);

  useEffect(() => {
    setHasmounted(true);
  }, []);

  return (<>
    <HeaderContainerSizeAdjust top={headerTop} bannerHeight={headerHeight} viewportWidth={width} isNewHeader={isNewHeader}/>
    <HeaderContainer className={'header'}>
      <HeaderContentContainer id="gnb">
        <HeaderTopBannerContainer isMobile={isMobile}>
          <UnsupportedBrowser />
          {hasMounted && Boolean(bandBannerData?.length) && bandBanners}
        </HeaderTopBannerContainer>
        <HeaderGnbContainer>
          <Gnb
            cartCount={cartsCount}
            isCoupon={false}
            isMobile={isMobile}
            isLogin={status === UserInfoStatusEnum.authenticated}
            isLoginLoading={status === UserInfoStatusEnum.loading}
            myCash={cashData}
          />
          <SubGnb isMobile={isMobile} setIsShowExplore={setIsShowExplore} isShowExplore={isShowExplore} />
        </HeaderGnbContainer>
      </HeaderContentContainer>
    </HeaderContainer>
    </>
  );
};

export default Header;
