import React from 'react';
import { useTheme } from 'styled-components';

export default function LogoMobile({ className }) {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <svg width="68" height="20" viewBox="0 0 68 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35 1.73145H15.45L19.3 18.5314L14.05 18.5314L13.7 16.4314H8.09999L7.75 18.5314H2.5L6.35 1.73145ZM9.73333 6.63145H12.0667L13.0583 12.5814H8.74166L9.73333 6.63145Z"
        fill={color}
      />
      <path
        d="M27.7 1.73145C23.0608 1.73145 19.3 5.49225 19.3 10.1314C19.3 14.7706 23.0608 18.5314 27.7 18.5314H31.9V12.9314L28.0147 13.4613C25.9969 13.7364 24.2 12.1679 24.2 10.1314C24.2 8.09495 25.9969 6.52647 28.0147 6.80163L31.9 7.33145V1.73145H27.7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.35 1.73145C36.9041 1.73145 33.3 5.33555 33.3 9.78145V10.4814C33.3 14.9273 36.9041 18.5314 41.35 18.5314C45.7959 18.5314 49.4 14.9273 49.4 10.4814V9.78145C49.4 5.33555 45.7959 1.73145 41.35 1.73145ZM41.35 6.63145C39.6103 6.63145 38.2 8.04175 38.2 9.78145V10.4814C38.2 12.2211 39.6103 13.6314 41.35 13.6314C43.0897 13.6314 44.5 12.2211 44.5 10.4814V9.78145C44.5 8.04175 43.0897 6.63145 41.35 6.63145Z"
        fill={color}
      />
      <path d="M56.05 18.5314H50.8V1.73145H55.35L60.8154 10.7776L60.25 1.73145H65.5V18.5314H60.95L55.4846 9.48529L56.05 18.5314Z" fill={color} />
    </svg>
  );
}
