import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import { Box, Stack } from '@mui/material';
import dynamic from 'next/dynamic';
import { CustomLink, CustomText, Skeleton } from '@components/ui';
import Logo from 'src/assets/Logo';
import LogoMobile from 'src/assets/LogoMobile';
import useSettings from '@hooks/useSettings';
import { LANGUAGE_CODE, PAGE_TYPE } from 'src/constants';
import LogoBeta from 'src/assets/LogoBeta';
import GnbScrap from 'src/assets/GnbScrap';
import GnbCart from 'src/assets/GnbCart';
import GnbUser from 'src/assets/GnbUser';
import GnbUserArrow from 'src/assets/GnbUserArrow';
import { HoverItem } from '@components/wrapper';
import GameTabNewBadge from '@components/layout/Header/components/Gnb/GameTabNewBadge';
import { numberWithCommas } from '@lib';
import Search from '../Search/Search';
import MyPage from '../MyPage/MyPage';
import GnbUserFilled from '../../../../../src/assets/GnbUserFilled';
import { GnbMobileSearchWrapper, CashIndicator, GnbContainer, ThemeModeContainer, UserMenuSide, GnbIconsWrapper } from './Gnb.styles';
import SearchIcon from 'src/assets/icons/gnb/SearchIcon';
import Link from 'next/link';
import { PATH_MYPAGE } from 'src/routes/paths';
import { useRouter } from 'next/router';

const LoginBonusBadge = dynamic(() => import('./LoginBonusBadge').then(({ LoginBonusBadge }) => LoginBonusBadge), { ssr: false });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Gnb = ({ isMobile, isLogin, isLoginLoading, isCoupon, cartCount, myCash }): React.ReactElement => {
  // 페이지 이동
  const router = useRouter();
  // 번역 도구
  const { t, i18n } = useTranslation(['game', 'menu', 'mode']);
  // 마이페이지 메뉴 표시 여부
  const [isMypageDisplay, setIsMypageDisplay] = useState(false);
  // 모바일일 경우 서치바 토글
  const [toggleSearchBar, setToggleSearchBar] = useState(false);
  // 마이페이지 영역
  const mypageRef = useRef(null);
  const { themeMode } = useSettings();
  const theme = useTheme();

  useEffect(() => {
    // 검색 페이지에서 다른 페이지로 이동 시 검색어 초기화.
    const handleRouterChange = () => setToggleSearchBar(false);

    router.events.on('routeChangeStart', handleRouterChange);
    return () => {
      router.events.off('routeChangeStart', handleRouterChange);
    };
  }, []);

  return (
    <GnbContainer>
      {[LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE) ? (
        <>
          <CustomLink href={`/${themeMode}`} className={'gnb-logo'}>
            <Logo className={'gnb-logo__normal'} />
            <LogoMobile className={'gnb-logo__mobile'} />
          </CustomLink>
          <ThemeModeContainer>
            {[PAGE_TYPE.TOON, PAGE_TYPE.GAME].map((mode) => {
              const isActive = mode === themeMode;
              return (
                <CustomLink href={`/${mode}`} key={mode} display={'flex'}>
                  {mode === 'game' && <GameTabNewBadge top={0} left={-5} />}
                  <CustomText
                    size={isMobile ? 14 : 16}
                    lineHeight={isMobile ? '100%' : '24px'}
                    weight={isMobile ? (isActive ? 500 : 400) : 600}
                    style={{ whiteSpace: 'pre' }}
                    color={isActive ? theme.colors.activeText : theme.colors.text}
                    data-mixpanel-action="click"
                    data-mixpanel-evt={`${mode === PAGE_TYPE.TOON ? 'Toon' : 'Game'} Tab Click`}
                  >
                    {t(`mode:${mode}`)}
                  </CustomText>
                </CustomLink>
              );
            })}
          </ThemeModeContainer>
        </>
      ) : (
        <CustomLink href={'/'}>
          <LogoBeta />
        </CustomLink>
      )}
      <Search className={'gnb-search'} />
      {isLoginLoading ? (
        <Skeleton width={'80'} height={'50px'} className={'cash-indicator'} />
      ) : isLogin ? (
        <Link href={PATH_MYPAGE.depositList} className={'cash-indicator'}>
          <CashIndicator themeMode={themeMode}>{numberWithCommas(myCash)}</CashIndicator>
        </Link>
      ) : (
        <div className={'cash-indicator'} />
      )}
      <UserMenuSide>
        {isLoginLoading ? (
          <Skeleton width={'150px'} height={'14px'} marginLeft={10} />
        ) : isLogin ? (
          <Box justifyContent="space-between" width="100%">
            {toggleSearchBar && (
              <GnbMobileSearchWrapper>
                <Search className="mr-4" isMobile setToggleSearchBar={setToggleSearchBar} />
                <Box className="cursor-pointer" onClick={() => setToggleSearchBar(false)}>
                  <CustomText color={theme.colors.activeText} weight={600} size={14} style={{ whiteSpace: 'pre' }}>
                    {t('menu:gnb.cancel')}
                  </CustomText>
                </Box>
              </GnbMobileSearchWrapper>
            )}

            <GnbIconsWrapper>
              <CustomLink className={'cursor-pointer gnb-scrap'} href={'/mypage/clip-book'} display={'undefined'}>
                <GnbScrap />
              </CustomLink>
              <button className="search-icon-container" onClick={() => setToggleSearchBar(!toggleSearchBar)}>
                <SearchIcon size={24} />
              </button>
              <CustomLink className={'cursor-pointer'} href={'/mypage/cart'}>
                <GnbCart count={cartCount} />
              </CustomLink>
              <div
                className={'flex items-center justify-center relative cursor-pointer'}
                onClick={() => {
                  if (!isMypageDisplay) {
                    setIsMypageDisplay(true);
                  }
                }}
                onMouseOver={() => setIsMypageDisplay(true)}
                onMouseOut={() => setIsMypageDisplay(false)}
                ref={mypageRef}
              >
                <GnbUserFilled />
                <GnbUserArrow isOpen={isMypageDisplay} />
                {isMypageDisplay && <MyPage isCoupon={isCoupon} onClose={() => setIsMypageDisplay(false)} />}
              </div>
            </GnbIconsWrapper>
          </Box>
        ) : (
          <Box justifyContent="space-between">
            {toggleSearchBar && (
              <GnbMobileSearchWrapper>
                <Search className="mr-4" isMobile setToggleSearchBar={setToggleSearchBar} />
                <Box className="cursor-pointer" onClick={() => setToggleSearchBar(false)}>
                  <CustomText color={theme.colors.activeText} weight={600} size={14} style={{ whiteSpace: 'pre' }}>
                    {t('menu:gnb.cancel')}
                  </CustomText>
                </Box>
              </GnbMobileSearchWrapper>
            )}

            <GnbIconsWrapper>
              <button className="search-icon-container" onClick={() => setToggleSearchBar(!toggleSearchBar)}>
                <SearchIcon size={24} />
              </button>
              <HoverItem>
                <CustomLink className={'cursor-pointer'} href={'/mypage/cart'}>
                  <GnbCart count={cartCount} />
                </CustomLink>
              </HoverItem>
              <CustomLink className={'flex items-center justify-center relative cursor-pointer'} href={`/${i18n.language}/users/login`}>
                <GnbUser />
              </CustomLink>
            </GnbIconsWrapper>
          </Box>
        )}
        <LoginBonusBadge />
      </UserMenuSide>
    </GnbContainer>
  );
};

export default Gnb;
