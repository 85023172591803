

export const normalizeSrc = (src) => src[0] === '/' ? 'https://www.acon3d.com/' + src.slice(1) : src;

export const cloudflareLoader = ({ src, width, quality, watermark }: {
  src: string;
  width?: number;
  quality?: number;
  watermark?: boolean | string;
}) => {
  //  gif 확장자의 경우 최적화 하지 않음
  if (src.endsWith('.gif')) return src;

  let widthQuery = '';
  if (width && !Number.isNaN(parseInt(String(width), 10))) widthQuery += `&width=${width}`;

  let qualityQuery = `&quality=${quality || 85}`;

  let watermarkQuery = '';
  if (watermark) {
    watermarkQuery += '&watermark=true';
    if (typeof watermark === 'string') watermarkQuery += `&watermark-image-url=${watermark}`;
  }
  return `${process.env.NEXT_PUBLIC_IMAGEOPTIMIZER}/?image=${normalizeSrc(src)}${widthQuery}${qualityQuery}${watermarkQuery}`;
};
