import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { custom } from 'zod';
import { useIsElementInViewport } from '@hooks/useIsElementInViewport';
import { cloudflareLoader } from '@components/ui/Image/CustomImage.constants';
import { ICustomImage } from './CustomImage.types';
import { CustomImageContainer, CustomImageBody } from './CustomImage.styles';

export const CustomImage: FC<ICustomImage> = (props: ICustomImage) => {
  const {
    src: srcOrigin,
    width,
    height,
    objectFit = 'cover',
    watermark,
    noLazyLoading,
    backgroundColor,
    className,
    containerClassName,
    useHeightAdjust = false,
    isUnoptimized: unoptimizedFromProps = false,
    sizes,
    ...rest
  } = props;
  const src = srcOrigin;
  const [unoptimized, setUnoptimized] = useState(unoptimizedFromProps
    || props.src.startsWith('/imgs')
    || props.src.endsWith('gif'));

  const { elementRef: imgRef, isVisible } = useIsElementInViewport({
    rootMargin: '600px 0px',
  }, noLazyLoading);

  // if (!rest.layout) {
  //   rest.layout = 'fill';
  // }

  const style: any = {
    height,
    backgroundColor: 'white',
  };

  let timeout = null;
  useEffect(() => {
    if (!imgRef?.current || !props.checktimeout) return;
    const img = imgRef.current;
    timeout = setTimeout(function() {
      if (!img.complete) { // @ts-ignore
        props.onError && props.onError({ target: img });
        img.setAttribute('data-timeout', 'true');
      }
    }, 1000);
    return ()=> {
      clearTimeout(timeout);
    };
  }, [imgRef.current]);

  if (!props?.src) {
    return <div style={{
      width,
    }} className="custom-img"></div>;
  }

  useEffect(()=> {
    if (unoptimizedFromProps === false
      || !src
      || ['png', 'gif', 'jpg', 'jpeg'].some(ext => src.includes(`.${ext}`))) return;
    axios.head(src).then((res)=> {
      if (res.headers['content-type'] === 'image/gif') {
        setUnoptimized(true);
      }
    });
  }, [src]);


  const sources = unoptimized && !Number.isNaN(parseInt(String(width), 10)) ? {
    src: props.src,
  } : {
    src: cloudflareLoader({ src: props.src, width: parseInt(String(width), 10), quality: props.quality || 85, watermark }),
    srcSet: `${cloudflareLoader({ src: props.src, width: parseInt(String(width), 10), quality: props.quality || 85, watermark })} 1x, `
    + `${cloudflareLoader({ src: props.src, width: parseInt(String(width), 10) * 2, quality: props.quality || 85, watermark })} 2x, `
    + `${cloudflareLoader({ src: props.src, width: parseInt(String(width), 10) * 3, quality: props.quality || 85, watermark })} 3x`,
  };

  if (backgroundColor) style.backgroundColor = backgroundColor;

  const onErrorEnhanced = (event)=> {
    (event.target as HTMLImageElement).classList.add('errored');
    setUnoptimized(true);
    if (event.target?.srcset?.includes('cdn-cgi/image')) {
      event.target.src = '';
      event.target.srcset = '';
      return;
    }
    props?.onError && props.onError(event);
  };

  const notInViewport = {
    src: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
  };


  return (
    <CustomImageContainer style={style} className={'custom-img ' + containerClassName}>
      <CustomImageBody
        ref={imgRef}
        onLoad={(e)=>{
          props.onLoad && props.onLoad(e);
          clearTimeout(timeout);
          (e.target as HTMLImageElement).classList.add('loaded');
        }}
        onError={onErrorEnhanced}
        onAbort={props.onError}
        className={className}
        {...{ width, height }}
        {...(isVisible ? sources : notInViewport)}
        objectFit={objectFit}
        alt={encodeURIComponent(props.src)}
      />
    </CustomImageContainer>
  );
};

export default CustomImage;
