import React from 'react';
import { useTheme } from 'styled-components';

export default function Logo({ className }: {className?: string }) {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <svg width="90" height="25" viewBox="0 0 90 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 0.6875H18.5L24 24.6874L16.5 24.6875L16 21.6875H7.99999L7.5 24.6874H0L5.5 0.6875ZM10.3333 7.6875H13.6667L15.0833 16.1875H8.91666L10.3333 7.6875Z"
        fill={color}
      />
      <path
        d="M36 0.6875C29.3726 0.6875 24 6.06007 24 12.6875C24 19.3149 29.3726 24.6874 36 24.6874H42V16.6875L36.4496 17.4444C33.567 17.8375 31 15.5968 31 12.6875C31 9.77822 33.567 7.53754 36.4496 7.93062L42 8.6875V0.6875H36Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.5 0.6875C49.1487 0.6875 44 5.83623 44 12.1875V13.1875C44 19.5388 49.1487 24.6875 55.5 24.6875C61.8513 24.6875 67 19.5388 67 13.1875V12.1875C67 5.83623 61.8513 0.6875 55.5 0.6875ZM55.5 7.6875C53.0147 7.6875 51 9.70222 51 12.1875V13.1875C51 15.6728 53.0147 17.6875 55.5 17.6875C57.9853 17.6875 60 15.6728 60 13.1875V12.1875C60 9.70222 57.9853 7.6875 55.5 7.6875Z"
        fill={color}
      />
      <path d="M76.5 24.6875H69V0.6875H75.5L83.3077 13.6106L82.5 0.6875H90V24.6875H83.5L75.6923 11.7644L76.5 24.6875Z" fill={color} />
    </svg>
  );
}
