import React from 'react';
import styled from 'styled-components';
import { getBreakpointedMedia, getColor } from '@util/themeUtil';
import { CustomText } from '@components/ui';

export const GnbContainer = styled.div`
  width: min(100%, 1444px);
  display: flex;
  height: 104px;
  align-items: center;
  gap: 24px;

  & > .gnb-logo {
    & > .gnb-logo__mobile {
      display: none;
    }
  }
  & > .gnb-search {
    width: min(100%, 382px);
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  @media screen and (min-width: 769px) {
    padding: 0 74px;
  }
  ${getBreakpointedMedia('down', 'md')} {
    padding: 0 16px;
  }
  @media screen and (max-width: 768px) {
    height: 48px;
    justify-content: space-between;
    & > .gnb-logo {
      order: 2;
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translate(-50%, -50%);
      & > .gnb-logo__normal {
        display: none;
      }
      & > .gnb-logo__mobile {
        display: block;
      }
    }
  }
  .cash-indicator {
    margin-right: -8px;
    @media screen and (min-width: 769px) {
      margin-left: auto;
    }
  }
`;

export const ThemeModeContainer = styled.div`
  display: flex;
  gap: 12px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  & > a {
    position: relative;
    padding: 10px 12px;
    height: 36px;
    display: flex;
    align-items: center;
  }
`;

export const UserMenuSide = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
  order: 3;
  .gnb-scrap {
    display: none;
  }
  @media screen and (min-width: 769px) {
    .search-icon-container {
      display: none;
    }
  }
  ${getBreakpointedMedia('up', 'lg')} {
    .gnb-scrap {
      display: block;
    }
  }
`;

export const CashIndicator = styled.div<{ themeMode?: string }>`
  order: 1;
  display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  border: 1px solid ${getColor('gray', 100)};
  color: ${(props) => (props.themeMode === 'toon' ? 'black' : 'white')};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  // GNB nested style 로 편입
  //margin-right: -8px;
  //@media screen and (min-width: 769px) {
  //  margin-left: auto;
  //}
  // GNB nested style 로 편입
  &::before {
    content: '';
    display: flex;
    width: 14px;
    height: 14px;
    background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8.51666 4.20004H5.48332L4.19999 9.80003H5.94999L6.06665 8.75004H7.93332L8.04999 9.80004L9.79999 9.80003L8.51666 4.20004ZM7.38888 5.83338H6.6111L6.28054 7.81671H7.71943L7.38888 5.83338Z" fill="%23FFA800"/></svg>');
  }

  @media screen and (max-width: 768px) {
    padding: 2px 8px;
  }
`;

export const GnbMobileSearchWrapper = styled.div<React.CSSProperties>`
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  padding: 6px 16px;
  background-color: ${(props) => props.theme.colors.gnbBg};
  justify-content: space-between;
  align-items: center;
`;

export const LoginBonusBadgeContainer = styled.div<{ onClick }>`
  background-color: #313135;
  border-radius: 4px;
  padding: 6px 8px;
  position: absolute;
  top: 35px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);

  &:after {
    width: 8px;
    height: 8px;
    background: #313135;
    border-radius: 2px 0px 0px 0px;
    transform: rotate(45deg);
    right: 15.69px;
    content: '';
    top: -4px;
    position: absolute;
  }
`;

export const LoginBonusBadgeText = styled(CustomText)`
  white-space: nowrap;
`;

export const GnbIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 768px) {
    gap: 8px;
  }
`;
