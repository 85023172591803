import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { stripParagraphTagParser } from '@util/transform';
import { Close, CloseMobile } from './close';
import Timer from './timer';
import { Band, BandMobile, BandTitle, BandTitleMobile, Wrapper, WrapperMobile } from './styled';

interface props {
  href: string;
  isBlank: boolean;
  backgroundColor: string;
  text: string;
  endDate: string;
  closedShow: string;
  closeColor: string;
  isMobile?: boolean;
  onClickRemove: MouseEventHandler;
}

export const BandBanner: React.FC<props> = ({ onClickRemove, isMobile, href, isBlank, backgroundColor, text, endDate, closedShow, closeColor, ...rest }) => {
  const router = useRouter();
  const openTargetWindow = isBlank ? '_blank' : '';
  const ref = useRef(null);
  const timerRef = useRef(null);

  const [isShowTimer, setIsShowTimer] = useState(true);

  const handleResize = () => {
    if (!timerRef.current) return;

    const timerRect = timerRef.current.getBoundingClientRect();
    const bannerRect = ref.current.getBoundingClientRect();

    // 배너 텍스트와 타이머가 충돌하면
    if (timerRect.x - 20 < bannerRect.x + bannerRect.width && timerRect.x + 20 + timerRect.width > bannerRect.x) setIsShowTimer(false);
    else setIsShowTimer(true);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      handleResize();
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (<>
  <WrapperMobile {...rest}/* className={'bandbanner_mobile'}*/>
    <a href={href || '#'} target={openTargetWindow} rel="noreferrer" className="top__banner">
      <BandMobile>
        <BandTitleMobile>
          <div>{stripParagraphTagParser(text)}</div>
        </BandTitleMobile>
        <CloseMobile
          onClick={(e) => {
            onClickRemove(e);
          }}
        />
      </BandMobile>
    </a>
  </WrapperMobile>
    <Wrapper {...rest}>
      <a href={href || '#'} target={openTargetWindow} rel="noreferrer" className="top__banner">
        <Band color={backgroundColor}>
          <BandTitle color={closeColor}>
            <div ref={ref}>{stripParagraphTagParser(text)}</div>
          </BandTitle>
          {endDate && closedShow !== 'none' ? <Timer ref={timerRef} isShow={isShowTimer} targetDate={endDate} displayType={closedShow} color={closeColor}></Timer> : null}
          <Close
            buttonColor={closeColor}
            onClick={(e) => {
              onClickRemove(e);
            }}
          />
        </Band>
      </a>
    </Wrapper>
  </>);
};
