import React from 'react';
import Link from 'next/link';
import { unescape } from 'lodash';
import { useRouter } from 'next/router';
import { GroupContainer, GroupContents, GroupItem, GroupItemLink, GroupItemRank, GroupTitle } from '@components/layout/Header/components/Group/Group.styles';
import useSettings from '@hooks/useSettings';
import { useSearchKeywordStore } from 'src/stores/searchKeyword/useSearchKeyword.store';
import { IGroupProps } from './Group.types';

const Group = ({ title, items, isPopulKeyword, onBlurInput }: IGroupProps): React.ReactElement => {
  const { themeMode } = useSettings();
  const setKeyword = useSearchKeywordStore((state) => state.setSearchKeyword);

  const handleSearchItemClick = (keyword: string) => {
    onBlurInput();
    setKeyword(keyword);
  };

  return (
    <GroupContainer>
      <GroupTitle isPopul={isPopulKeyword}>{title}</GroupTitle>
      <GroupContents>
        {items &&
          items.map((keyword, i) => (
            <Link href={`/${themeMode}/search?keyword=${keyword}`} prefetch={false} legacyBehavior key={i}>
              <GroupItem
                key={i}
                onClick={() => handleSearchItemClick(keyword)}
                data-mixpanel-action="click"
                data-mixpanel-evt="Trigger Search"
                data-mixpanel-keyword={keyword}
                data-mixpanel-method={isPopulKeyword ? 'popular' : 'recent'}
              >
                <GroupItemLink>
                  {/* 인기 검색어 순위 3위까지 분홍색 그 이하는 검은색으로 설정 */}
                  {isPopulKeyword && <GroupItemRank color={i <= 2 ? '#4E4EFF' : '#333'}>{i + 1}.</GroupItemRank>}
                  {unescape(keyword)}
                </GroupItemLink>
              </GroupItem>
            </Link>
          ))}
      </GroupContents>
    </GroupContainer>
  );
};

export default Group;
