import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FlexRow } from '@components/wrapper';
import { getColor } from '@util/themeUtil';

export const Wrapper = styled.div<{ themeMode: string }>`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.gnbBg};

  @media screen and (max-width: 768px) {
    height: 40px;
    border-top: ${(props) => `1px solid ${props.theme.colors.gnbDivder || 'transparent'}`};
  }

  ${(props) => props.themeMode === 'game' && css`
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @supports (overflow: overlay) {
      overflow-x: overlay;
      overflow-y: overlay;
      ::-webkit-scrollbar {
        width: 0;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: transparent;
        width: 0;
        border: solid #ffffff 0px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(136, 136, 138);
      }
    }
  `}
`;
export const Container = styled(FlexRow)<{ themeMode: 'game' | 'toon' }>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 56px;
  position: relative;

  @media screen and (max-width: 768px) {
    align-items: center;
    gap: 20px;
  }

  &::before {
    ${(props) =>
      props.themeMode === 'toon' && css`
        pointer-events: none;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 1px;
        background-color: #E7E7E8;
        `}
  }
`;

export const CategoryButton = styled.div`
  cursor: pointer;
  .gnb__category_wrapper {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const CommonLi = styled.div`
  position: relative;
  height: 100%;
  display: inherit;
  align-items: inherit;
  &.isActive {
    color: #313135;
    box-shadow: inset 0 -2px 0 0 #313135;
    & > .subMenus {
      display: flex;
    }
    &:hover {
      box-shadow: inset 0 -2px 0 0 #31313566;
    }
  }
  &:hover > .subMenus {
    display: flex;
  }
`;

export const DevideLineContainer = styled.div`
  display: inherit;
  align-items: inherit;
  color: #313135;
  @media screen and (max-width: 768px) {
    color: #88888a;
  }
`;

export const DevideLine = styled.div`
  width: 1px;
  height: 20px;
  margin-top: 1px;
  @media screen and (max-width: 768px) {
    height: 16px;
    margin-top: 2px;
  }
`;

export const SubMenu = styled(Link)`
  position: relative;
  height: 100%;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  color: ${getColor('gray', 500)};
  white-space: pre;
  font-weight: 400;
  font-size: 14px;
  &.isActive {
    font-weight: 600;
    color: ${getColor('gray', 800)};
  }
  
  &:hover {
    opacity: 0.4;
  }
  
  &.isSubmenu:hover {
    opacity: 1!important;
    font-weight: 600;
    color: ${getColor('gray', 800)};
  }

  @media screen and (max-width: 768px) {
    padding: 12px 8px;
  }
`;
export const SubMenus = styled.div.attrs({ className: 'subMenus' })`
  position: absolute;
  top: 100%;
  display: none;
  height: 48px;
  left: 0;
  align-items: center;
  opacity: 1 !important;

  & > div {
    z-index: 2;
    display: flex;
    height: 100%;

    @media screen and (max-width: 768px) {
      position: relative;
      left: -110px;
    }
  }

  & > div > * {
    position: relative;
    z-index: 2;
  }
`;
