import styled from 'styled-components';
import { NoBorderButton } from '@components/ui';

export const SearchFooter = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 26px;
  border-top: 1px solid #f5f5f6;
`;

export const SearchFooterLink = styled.a`
  color: #f300ba;
  cursor: pointer;

  & > div {
    vertical-align: middle;
  }
`;

export const RemoveAllItemButton = styled(NoBorderButton)`
  font-size: 12px;
  line-height: 100%;
  color: #aaa;
  font-weight: 400;
  position: absolute;
  top: 27px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ListContainer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : 'calc(100% - 40px)')};
  position: ${(props) => (props.isMobile ? 'fixed' : 'absolute')};
  background-color: white;
  left: ${(props) => (props.isMobile ? '0' : '20px')};
  top: ${(props) => (props.isMobile ? '40px' : '32px')};
  bottom: ${(props) => (props.isMobile ? '0' : 'auto')};
  z-index: 9999;
  box-shadow: ${(props) => (props.isMobile ? 'none' : '0px 10px 18px rgb(0 0 0 / 20%)')};
  border-radius: ${(props) => (props.isMobile ? '0px' : '4px')};
  padding-top: 18px;
  box-sizing: border-box;

  ${(props) =>
    props.isMobile &&
    `
    & ${RemoveAllItemButton} {
      padding-right: 8px;
    }  
  `}
`;
