import styled from 'styled-components';
import { LANGUAGE_CODE } from 'src/constants';
import { MainImage, SubImage } from './cardimg/styled';

// Card.tsx
export const CardContainer = styled.div<{
  isShowContainer: boolean;
  isDisplayOnScrap?: boolean;
  isScrapEdit?: boolean;
  isChecked?: boolean;
  isDisableThumbnail?: boolean;
  isBorderRound?: boolean;
}>`
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  position: relative;
  ${(props) => (props.isScrapEdit ? 'overflow: visible' : 'overflow: hidden')};
  ${(props) => (props.isScrapEdit ? 'cursor: pointer' : 'cursor: auto')};
  ${(props) =>
    props.isBorderRound &&
    `
    padding: 6px;
    background-color: #fff;
    border-radius: 12px;
  `}

  ${(props) => {
    if (props.isDisableThumbnail) return '';

    if (props.isScrapEdit && props.isChecked) {
      return `
        ${SubImage} {
          display: none;
        }
      `;
    }

    if (props.isScrapEdit) {
      return `
        &:hover ${MainImage} {
          opacity: 0.5;
        }
        ${SubImage} {
          display: none;
        }
      `;
    }

    return `
      &:hover ${MainImage} {
        opacity: 0;
      }
    `;
  }}

  ${(props) => (props.isShowContainer ? 'height: auto;' : 'height: 180px;')}

  ${(props) =>
    !props.isDisplayOnScrap &&
    `&:nth-last-of-type(-n + 3) {
      margin-bottom: 0px;
    }`}
`;

export const CardHeader = styled.div<{ isDisabled?: boolean; isScrapBorder?: boolean }>`
  position: relative;
  overflow: hidden;
  height: 200px;
  border-radius: 6.65px;
  border: 1px #eeeeee solid;
  ${(props) => (props.isDisabled ? 'pointer-events: none;' : '')};

  .delete-scrap {
    display: none;
    pointer-events: none;
  }

  &:hover .delete-scrap {
    display: flex;
    pointer-events: auto;
  }
`;

export const CardContents = styled.div<{ isDisabled?: boolean }>`
  padding: 12px 6px;
  ${(props) => (props.isDisabled ? 'pointer-events: none;' : '')};
`;

export const CardBody = styled.div``;

export const CardMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  & > img {
    cursor: pointer;
  }
`;

export const CardCreater = styled.p`
  font-size: 12px;
  cursor: pointer;
  & > a {
    color: #a0a0a0;
    line-height: 100%;
    display: block;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 12px;
`;

export const CardTitleText = styled.div<{ lang: string }>`
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.01em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  overflow: hidden;
  word-break: keep-all;
  line-height: 140%;
  font-weight: 400;
  color: #1a1a1a;
  ${(props) =>
    props.lang !== LANGUAGE_CODE.EN &&
    `
    word-break: break-all;
  `}
`;

export const CardPriceContainer = styled.div.attrs({
  className: 'flex',
})`
  align-items: flex-end;
  margin-bottom: 12px;
  line-height: 100%;
`;

export const CardPercentText = styled.span.attrs({
  className: 'mr-2',
})`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  line-height: 100%;
  ${(props) => `
    color: ${props['data-active'] ? '#ef00f4' : '#7c7c7c'};
  `}
`;

export const CardRealPriceText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 10px;
`;

// Card -> Footer
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardExtension = styled.span`
  display: flex;
`;

export const CardReview = styled.div`
  display: flex;
  & > div {
    vertical-align: top;
  }
`;

export const CardReviewCount = styled.span`
  margin: 0px 8px 0px 4px;
  color: #a0a0a0;
  font-size: 12px;
  vertical-align: top;
  line-height: 100%;
`;

export const CardBadgeContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 5;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: calc(100% - 50px);
`;

export const CardCount = styled.div`
  display: flex;
`;

export const CardCheckBox = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 14px;
  right: 14px;
  background: ${(props) => (props.isChecked ? '#4E4EFF' : '#FFFFFF')};
  border: ${(props) => (props.isChecked ? '0' : '1.2px solid #A2A2A4')};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto;
`;

export const MainImageDimming = styled.div`
  width: 100%;
  height: 200px;
  background: rgba(231, 231, 232, 0.8);
  border-radius: 6.65px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  border: 1.5px solid #4e4eff;
`;

export const DeleteScrap = styled.button.attrs({ className: 'delete-scrap' })`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  right: 11px;
  top: 12px;
  width: 36px;
  height: 36px;
  background: rgba(24, 24, 27, 0.8);
  border: 1px solid #18181b;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const AconOnly = styled.div.attrs({
  className: 'flex',
})`
  margin-bottom: 6px;
`;

export const EndSales = styled.div.attrs({
  className: 'absolute z-10 bottom-0 right-0 w-full',
})`
  padding: 7px 0px 8px 0px;
  text-align: center;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0.6;
    z-index: 1;
  }
`;
