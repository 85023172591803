import React from 'react';
import styled from 'styled-components';
import { NoBorderInput } from '@components/ui';

export const SearchContainer = styled.div<{ isMobile: boolean } & React.CSSProperties>`
  background-color: ${(props) => props.theme.colors.searchBg};
  border: 1px solid ${(props) => props.theme.colors.searchBorder};
  padding: 0px 20px;
  border-radius: 17px;
  height: 34px;
  width: 388px;
  position: relative;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.isMobile &&
    `
    width: 100%;
  `}
`;

export const SearchPlaceholder = styled.div<React.CSSProperties>`
  pointer-events: none;
  position: absolute;
  font-size: 14px;
  color: ${(props) => props.theme.colors.searchText};
  line-height: 20px;
  padding-top: ${(props) => `${props.paddingTop}px`};
`;
export const SearchInput = styled(NoBorderInput)`
  flex: 1;
  margin-right: 20px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.searchText};
  width: 100%;
`;
