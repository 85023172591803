import React from 'react';
import styled from 'styled-components';

export const GroupContainer = styled.div<React.CSSProperties>`
  padding: 0 16px 12px 16px;
`;

export const GroupTitle = styled.div<{ isPopul: boolean }>`
  font-size: 16px;
  line-height: 24px;
  color: #333;
  padding: 8px;
  font-weight: 600;
`;

export const GroupContents = styled.div``;

export const GroupItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 7px 8px;
  cursor: pointer;
  color: #333333;
`;

export const GroupItemLink = styled.a`
  display: block;
  width: 100%;
`;

export const GroupItemRank = styled.span`
  margin-right: 3px;
  font-weight: 500;

  ${(props) => `
        color: ${props.color};
    `}
`;
