import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CustomText } from '@components/ui';

const CountBadge = styled.div<{ isLarge: boolean; badgeColor?: string }>`
  position: absolute;
  background-color: ${(props) => props.badgeColor || props.theme.colors.gnbCount};
  min-width: ${(props) => (props.isLarge ? 26 : 17)}px;
  height: 16px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  left: 15px;
`;

export default function GnbCart({ className = '', count = null, isLogin = true }) {
  const theme = useTheme();
  const size = 24;

  return (
    <div className={`relative ${className}`}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6564 6.0452H21L19 13.5H9.5L6.94786 3H3" stroke={theme.colors.icon} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="10.4739" cy="19" r="2" fill={theme.colors.icon} />
        <circle cx="17.4739" cy="19" r="2" fill={theme.colors.icon} />
      </svg>

      {count > 0 && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CountBadge isLarge={count >= 99} badgeColor={!isLogin ? '#4E4EFF' : undefined}>
          <CustomText size={10} weight={500} color={theme.colors.gnbCountText}>
            {count >= 99 ? '99+' : count}
          </CustomText>
        </CountBadge>
      )}
    </div>
  );
}
