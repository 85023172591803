import styled, { css, keyframes } from 'styled-components';
import { CSSProperties } from 'react';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;
export const CustomImageBody = styled.img<{useHeightAdjust?: boolean; objectFit?: CSSProperties['objectFit']}>`
  position: relative;
  object-position: center;
  &.loaded {
    display: block;
  }
  ${props => css`
    object-fit: ${props.objectFit || 'cover'};
    width: ${props.width ? `${props.width}px` : '100%'};
    height: ${props.height ? `${props.height}px` : '100%'};
    ${!props.height && props.useHeightAdjust && css`
      max-height: ${(parseInt(String(props.width), 10) * 2)}px;
      min-height: ${Math.round(parseInt(String(props.width), 10) / 2)}px;
    `
}`}
`;
export const CustomImageContainer = styled.div`
  position: relative;
  width: auto;
  &:not(:has(.loaded), :has(.errored))::before {
    z-index: 1;
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lightgray;
    animation: ${skeletonAnimation} 1.5s ease-in-out 6;
  }
`;
