import React from 'react';
import { IconProps } from '../type';
import * as Styled from '../styled';

type Props = IconProps & {
  customStroke?: number;
};

export default function ChevronRightIcon({ className, size }: Props) {
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.33334 15.8331C8.13863 15.8335 7.94993 15.7657 7.8 15.6415C7.62958 15.5002 7.52238 15.2969 7.50206 15.0764C7.48173 14.856 7.54996 14.6365 7.69167 14.4665L11.425 9.99979L7.825 5.52479C7.6852 5.35263 7.61978 5.13184 7.64324 4.91131C7.6667 4.69077 7.7771 4.48869 7.95 4.34979C8.12431 4.19643 8.35469 4.12273 8.58565 4.14646C8.81661 4.17019 9.02719 4.28919 9.16667 4.47479L13.1917 9.47479C13.4444 9.78229 13.4444 10.2256 13.1917 10.5331L9.025 15.5331C8.85545 15.7377 8.59853 15.8491 8.33334 15.8331Z"
          fill="#4E4EFF"
        />
      </svg>
    </Styled.IconWrapper>
  );
}
