import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';
import { SearchContainer, SearchInput, SearchPlaceholder } from '@components/layout/Header/components/Search/Search.styles';
import Searcher from 'src/assets/Searcher';
import * as mixpanel from '@lib/mixpanel';
import { PATH_PAGE } from 'src/routes/paths';
import { throttleWrapper } from '@util/eventHandling';
import { LANGUAGE_CODE } from 'src/constants';
import { useSearchKeywordStore } from 'src/stores/searchKeyword/useSearchKeyword.store';
import List from '../List/List';
import { ISearchProps } from './Search.types';

const Search = ({ className, isMobile, setToggleSearchBar }: ISearchProps): React.ReactElement => {
  // 페이지 이동
  const router = useRouter();
  // 키워드
  const keyword = useSearchKeywordStore((state) => state.searchKeyword);
  const setKeyword = useSearchKeywordStore((state) => state.setSearchKeyword);
  // 포커스 여부
  const [isFocus, setIsFocus] = useState(isMobile);
  // 번역도구
  const { t, i18n } = useTranslation(['menu']);
  // 최근 항목들
  const [recentItems, setRecentItems] = useLocalStorage<[string?]>('acon-search-keyword', []);

  // input focus
  const handleInput = () => {
    // 포커스 true
    setIsFocus(true);
  };
  // input blur
  const handleInputBlur = () => {
    // 포커스 false
    setIsFocus(false);
    (document.activeElement as HTMLInputElement).blur();
    setToggleSearchBar && setToggleSearchBar(false);
  };
  // 검색 메소드
  const searchKeyword = throttleWrapper(async () => {
    if (!keyword?.trim()) {
      alert(t('menu:gnb.search.placeholder'));
      setKeyword('');
      return;
    }

    if (recentItems.includes(keyword)) {
      recentItems.splice(recentItems.indexOf(keyword), 1);
    } else {
      // 최근 검색 항목이 4이상이면 오래된 항목 삭제
      if (recentItems.length >= 5) recentItems.pop();
    }
    // 최근 검색어 추가
    recentItems.unshift(keyword);
    setRecentItems(recentItems);

    mixpanel.event('Trigger Search', {
      keyword: keyword,
      method: 'typing',
    });

    handleInputBlur();

    return await router.push({
      pathname: PATH_PAGE.search,
      query: {
        keyword: keyword,
      },
    });
  });
  // 최근 항목 전체 삭제 버튼 클릭 이벤트 처리기 메소드
  const handleRemoveAllItemButtonClick = () => {
    // 최근항목 초기화
    setRecentItems([]);
  };

  useEffect(() => {
    if (!isMobile) return;
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = null);
  }, []);

  return (
    <SearchContainer lang={i18n.language} className={className} isMobile={isMobile}>
      {!isFocus && !keyword && <SearchPlaceholder paddingTop={i18n.language === LANGUAGE_CODE.KO ? 1 : 0}>{t('menu:gnb.search.placeholder')}</SearchPlaceholder>}
      <SearchInput
        value={keyword}
        onClick={handleInput}
        onFocus={handleInput}
        onBlur={handleInputBlur}
        onChange={(e) => {
          setKeyword(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) searchKeyword();
        }}
      />
      <Searcher onClick={searchKeyword} className={'cursor-pointer ml-3'} />

      {isFocus && <List isMobile={isMobile} recentItems={recentItems} onBlurInput={handleInputBlur} onClickRemoveAllItemButton={handleRemoveAllItemButtonClick} />}
    </SearchContainer>
  );
};

export default Search;
